import { Injectable } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { PopupModel } from './popup-model';
import { PopupServiceComponent } from './popup-service.component';

@Injectable({
  providedIn: 'root'
})
export class PopupService {

  bsModalRef: BsModalRef;

  constructor(private modalService: BsModalService) { }

  private prepareObject(object) {
    for (const i in object) {
      if (object[i] === undefined) {
        delete object[i];
      }
    }
  }

  error(title, message, acceptBtnName?, acceptFunction?, closeBtnName?, closeFunction?, modalParentClass?) {
    const initialState: PopupModel = {
      title,
      content: message,
      closeBtnName,
      closeFunction,
      acceptBtnName,
      acceptFunction,
    };
    modalParentClass = modalParentClass ? modalParentClass : '';
    modalParentClass += ' error-modal';
    this.prepareObject(initialState);
    this.bsModalRef = this.modalService.show(PopupServiceComponent, {class: modalParentClass, initialState});
  }

  info(title, message, acceptBtnName?, acceptFunction?, closeBtnName?, closeFunction?, modalParentClass?) {
    const initialState: PopupModel = {
      title,
      content: message,
      closeBtnName,
      closeFunction,
      acceptBtnName,
      acceptFunction,
    };
    modalParentClass = modalParentClass ? modalParentClass : '';
    modalParentClass += '';
    modalParentClass += ' modal-dialog-centered';
    this.prepareObject(initialState);
    this.bsModalRef = this.modalService.show(PopupServiceComponent, {class: modalParentClass, initialState});
  }

  warning(title, message, acceptBtnName?, acceptFunction?, closeBtnName?, closeFunction?, modalParentClass?) {
    const initialState: PopupModel = {
      title,
      content: message,
      closeBtnName,
      closeFunction,
      acceptBtnName,
      acceptFunction,
    };
    modalParentClass = modalParentClass ? modalParentClass : '';
    modalParentClass += ' warning-modal';
    this.prepareObject(initialState);
    this.bsModalRef = this.modalService.show(PopupServiceComponent, {class: modalParentClass, initialState});
  }

}
