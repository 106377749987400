import { Global } from './../common/globals';
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class AddApiUrlInterceptor implements HttpInterceptor {

  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const finalReq = req.url.startsWith('http') || req.url.includes('/assets/') ? req :
      req.clone({ url: `${Global.apiUrl}${req.url}`});
    return next.handle(finalReq);
  }
}
