import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { PopupModel } from './popup-model';

@Component({
  selector: 'app-popup-service',
  templateUrl: './popup-service.component.html',
  styleUrls: ['./popup-service.component.scss']
})
export class PopupServiceComponent implements PopupModel {

  title: string;
  content: string;
  closeBtnName: string;
  closeFunction: any;
  acceptBtnName = 'Confirm';
  acceptFunction: any;

  constructor(public bsModalRef: BsModalRef) { }

  public onConfirm(): void {
    this.bsModalRef.hide();
    if (this.acceptFunction) {
      this.acceptFunction();
    }

  }

  public onDismiss(): void {
    this.bsModalRef.hide();
    if (this.closeFunction) {
      this.closeFunction();
    }
  }


}
