<div class="body-container">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-md-12">
                <div class="row">
                    <div class="col-sm-12 col-md-4" (click)="goTo('/tools')">
                        <div class="card">
                            <h2 class="blue">Quick Tools</h2>
                            <small>Common tools to manage common things</small>
                        </div>
                    </div>
<!--                    <div class="col-sm-12 col-md-4">-->
<!--                        <div class="card">-->
<!--                            <h2 class="blue">SOON</h2>-->
<!--                            <small>Manage S3 Files</small>-->
<!--                        </div>-->
<!--                    </div>-->
                    <div class="col-sm-12 col-md-4"  (click)="goTo('/database')">
                        <div class="card">
                            <h2 class="blue">Database Manager</h2>
                            <small>Manage database</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
