<div class="modal-header">
    <h4 class="modal-tittle pull-left">{{title}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <span class="alert-text">{{content}}</span>
</div>
<div class="modal-footer">
    <button *ngIf="closeBtnName" type="button" class="btn" (click)="onDismiss()">{{closeBtnName}}</button>
    <button  type="button" class="btn-solid" (click)="onConfirm()">{{acceptBtnName}}</button>
</div>