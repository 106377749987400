import { Global } from './../shared/common/globals';
import { environment } from 'src/environments/environment';
import { AuthorizationData } from './../shared/models/authorization-data.model';
import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../auth/auth.service';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  username: string;
  password: string;
  environment: string;
  badCredentials: boolean;
  badEnv: boolean;

  constructor(private authService: AuthenticationService, private router: Router) {
    this.environment = 'null';
    this.badCredentials = false;
    this.badCredentials = false;
  }

  ngOnInit(): void {
  }

  onSubmit(form: NgForm): void {
    if (this.environment === 'null') {
      this.badEnv = true;
      return;
    }
    const loginData: AuthorizationData = {
      username: this.username,
      password: this.password,
      'grant-type': 'password'
    };
    this.authService.login(loginData).subscribe(
      (response) => {
        this.router.navigate(['/home']);
      },
      (err) => {
        this.badCredentials = true;
        console.log(err);
      }
    );
  }

  environmentChange(data: string): void {
    this.badEnv = false;
    Global.apiUrl = environment[data];
    Global.defaultEnv.next(data.split('api')[1]);
    localStorage.setItem('env', environment[data]);
    localStorage.setItem('env-text', data.split('api')[1]);
  }
}
