<!-- DIV LOGIN -->
<div class="body-container">
    <!-- END DIV WRONG DATA -->
    <div class='row no-gutters align-items-center'>
        <div class='col-12'>
            <!-- DIV WRONG DATA -->

            <div class='login-wrapper'>
              <div style="margin-bottom: 15px;">
                <form>
                  <!-- [(ngModel)]="environment" (ngModelChange)="environmentChange($event)" -->
                  <div class="form-group col-md-12 p-0">
                    <select class="custom-select" [(ngModel)]="environment" (ngModelChange)="environmentChange($event)" name="environment">
                      <option value="null">Select Environment</option>
                      <option value="apiPRO">PRO</option>
                      <option value="apiPRE">PRE</option>
                      <option value="apiDEV">DEV</option>
                    </select>
                  </div>
                </form>
              </div>
                <form #loginForm="ngForm" (ngSubmit)="onSubmit(loginForm)">
                    <div>
                        <input type="text" name="username" id="username"  placeholder="Username" [(ngModel)]="username" />
                    </div>
                    <div>
                        <input type="password" name="password" id="password" placeholder="Password" [(ngModel)]="password" />
                    </div>
                    <!-- <div class="text-center alert alert-danger" *ngIf="errors" role="alert">
                        Wrong user or password
                     </div> -->
                    <div class='text-center'>
                        <button class='btn btn-reversed' type="submit">ENTER</button>
                    </div>
                </form>
              <div class="alert alert-danger bad-credentials" role="alert" *ngIf="badCredentials">
                Incorrect login data
              </div>
              <div class="alert alert-warning bad-credentials" role="alert" *ngIf="badEnv">
                Select an environment
              </div>
            </div>
        <!-- END DIV LOGIN -->
        </div>
    </div>
</div>
