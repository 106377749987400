import { Injectable } from '@angular/core';
import { AuthorizationData, UserLoggedModel } from '../shared/models';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';


@Injectable()
export class AuthenticationService {

    private currentUser: any;
    private crsfToken = null;
    private userLogged$: BehaviorSubject<UserLoggedModel | false>;
    private apiRoot = '/auth';


    constructor(private http: HttpClient) {
        this.userLogged$ = new BehaviorSubject<UserLoggedModel | undefined>(undefined);
     }

    private tapSetUser = tap((result: UserLoggedModel) => {
        const retValue = this.setUser(result);
        this.currentUser = retValue;
        this.userLogged$.next(retValue);
    });

    private setUser(result: UserLoggedModel): UserLoggedModel | false {
        const retValue: UserLoggedModel | false = result;
        if (result.token) {
            this.crsfToken = result.token;
            const expiresIn = Math.round(Date.now() + result.expires_in * 1000);
            localStorage.setItem('token', JSON.stringify({ token: result.token, expires_in: expiresIn}));
        }
        return retValue;
    }

    private _getUserLogged(): Observable<UserLoggedModel> {
        return this.http.get(`${this.apiRoot}/user_logged/`).pipe(
            this.tapSetUser, ) as Observable<UserLoggedModel>;
    }

    public getUserLogged(): Promise<UserLoggedModel | false> {
        const promise = new Promise<UserLoggedModel | false>((resolve, reject) => {
            if (this.currentUser === undefined) {
                this._getUserLogged().toPromise().then(data => {
                    resolve(this.setUser(data));
                });
            } else {
                resolve(this.currentUser);
            }
        });
        return promise;
    }

    public getUserLogged$(): Observable<UserLoggedModel | false> {
        // return this._getUserLogged();
        return this.userLogged$.asObservable();
    }
    public getUserLoggedSubject(): Observable<UserLoggedModel | false> {
        return this.userLogged$.asObservable();
    }

    public login(loginData: AuthorizationData): Observable<UserLoggedModel> {
        console.log(this.apiRoot);
        return this.http.post(`${this.apiRoot}/login/`, loginData).pipe(
            this.tapSetUser,
          ) as Observable<UserLoggedModel>;
    }

    public logout(): Observable<any> {
        this.userLogged$.next(undefined);
        this.currentUser = undefined;
        return this.http.get(`${this.apiRoot}/logout`);
    }

    public getCsrfToken() {
        return this.crsfToken;
    }
}
