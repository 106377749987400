import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { AuthenticationService } from '../../auth/auth.service';
import { Observable } from 'rxjs';


@Injectable()
export class HttpBearerInterceptor implements HttpInterceptor {

    constructor(private auth: AuthenticationService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const headerName = '';
        let token;
        if (localStorage.getItem('token') !== null) {
            token = JSON.parse(localStorage.getItem('token'));
            if (token.token !== null && !req.headers.has(headerName)) {
                req = req.clone({
                    setHeaders: {
                        Authorization: `Bearer ${token.token}`
                    }
                });
            }
        }
        return next.handle(req);
    }
}
