import { LandingComponent } from './landing/landing.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './auth/auth.guard';


const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: '',
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'home',
        component: LandingComponent
      },
      {
        path: 'database',
        loadChildren: () => import('./database-manager/database-manager.module').then(m => m.DatabaseManagerModule)
      },
      {
        path: 'tools',
        loadChildren: () => import('./common-tools/common-tools.module').then(m => m.CommonToolsModule)
      },
    ]
  },
  {
    path: 'login',
    component: LoginComponent
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
