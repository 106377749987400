import { environment } from 'src/environments/environment';
import { UserModel } from './../../shared/models/user.model';
import { UserLoggedModel } from './../../shared/models/user-logged.model';
import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/auth/auth.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { Router } from '@angular/router';
import { PopupService } from 'src/app/shared/popup-service/popup.service';
import { Global } from './../../shared/common/globals';


@Component({
  selector: 'app-header-layout',
  templateUrl: './header-layout.component.html',
  styleUrls: ['./header-layout.component.scss']
})
export class HeaderLayoutComponent implements OnInit {

  user$: UserLoggedModel ;
  nowDate: Date;
  environment;

  constructor(private auth: AuthenticationService,
              private popupService: PopupService,
              private router: Router) {
                this.environment = localStorage.getItem('env-text') || 'PRO';
              }

  ngOnInit() {
    // Set environment on header
    Global.defaultEnv.subscribe(
      (env) => this.environment = env
    );
    this.nowDate = new Date();
    this.auth.getUserLogged$().subscribe(
      (response: UserLoggedModel) => {
        if (response){
          this.user$ = response;
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  public onLogout(): void {
    const title = 'Logout';
    const message = 'Do you really want to sign out?';
    const acceptBtnName = 'Confirm';
    const closeBtnName = 'Close';
    this.popupService.warning(title, message, acceptBtnName, () => {
      this.logout();
    }, closeBtnName);
  }

  private logout(): void {
    this.auth.logout().subscribe(
      response => {
        this.environment = 'undefined';
        this.user$ = undefined;
        console.log(response.message);
        localStorage.clear();
        Global.apiUrl = environment.apiPRO;
        this.router.navigate(['/login']);
      },
      error => {
        console.log(error);
      }
    );
  }
}
