import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from './auth.service';



@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild{

    constructor(private authService: AuthenticationService, private router: Router) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
            if (localStorage.getItem('token') === null) {
                this.router.navigate(['/login']);
                return false;
            } else {
                const token = JSON.parse(localStorage.getItem('token'));
                const date = Date.now();
                if (date > token.expires_in) {
                    localStorage.removeItem('token');
                    this.router.navigate(['/login']);
                    return false;
                }
            }
            return this.authService.getUserLogged().then(
                (response) => {
                    return true;
                }
            );

    }

    canActivateChild(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
            return this.canActivate(next, state);
    }
}
