<!-- DIV HEADER -->
<div class="header-container">
  <div class='row align-items-center no-gutters'>
    <div class='col-md-auto col-sm-5'>
      <h2 class='font-bold text-light'>SOCIAL DISTANCING EDITOR Backoffice</h2>
    </div>
    <div id='header-right' class='col-md-auto ml-auto col-sm-7 text-right'>
      <span id='welcomemsg' class='header-right-item user-select-none' *ngIf="environment && user$">Environment: {{environment}}</span>
      <span id='welcomemsg' class='header-right-item user-select-none' *ngIf="user$">Welcome {{user$.user.username}}</span>
      <span id='welcomemsg' class='header-right-item user-select-none'>{{nowDate | date:'fullDate'}}</span>
      <button class='btn-reversed header-right-item' (click)="onLogout()" *ngIf="user$">LOGOUT</button>
    </div>
  </div>
</div>
<!-- END DIV HEADER -->