import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PopupServiceComponent } from './popup-service/popup-service.component';
import { PopupService } from './popup-service/popup.service';
import { ModalModule } from 'ngx-bootstrap/modal';
import { SearchTablePipe } from './pipes/search-table.pipe';




@NgModule({
  declarations: [
    PopupServiceComponent,
    SearchTablePipe
  ],
  imports: [
    CommonModule,
    ModalModule.forRoot(),
  ],
  providers: [
    PopupService
  ],
  exports: [
    SearchTablePipe
  ]
})
export class SharedModule { }
